/* eslint-disable no-console */
import React from 'react'
import ReactGA from 'react-ga'

import axios from 'axios'
import snakeCase from 'lodash/snakeCase'

import Flash from 'components/flash/Flash'

axios.interceptors.request.use(
  config => {
    config.metadata = { startTime: new Date() }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  response => {
    response.config.metadata.endTime = new Date()
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime
    return response
  },
  error => {
    error.config.metadata.endTime = new Date()
    error.duration = error.config.metadata.endTime - error.config.metadata.startTime
    return Promise.reject(error)
  }
)

export const toRailsHash = params =>
  Object.entries(params).reduce((acc, [key, val]) => ({ ...acc, [snakeCase(key)]: val }), {})

class Api {
  constructor() {
    const csrfToken = document.querySelector('meta[name=csrf-token]')
    this.token = csrfToken && csrfToken.content
  }

  delete(url, params) {
    return this.send('DELETE', url, params, {})
  }

  get(url, params, appendExt = true) {
    return this.send('GET', url, params, {}, appendExt)
  }

  patch(url, data) {
    return this.send('PATCH', url, {}, data)
  }

  post(url, data) {
    return this.send('POST', url, {}, data)
  }

  put(url, data, appendExt = true) {
    return this.send('PUT', url, {}, data, appendExt)
  }

  upload(url, data, method = 'POST') {
    const form = new FormData()

    Object.keys(data).forEach(key => {
      form.append(key, data[key])
    })

    return this.send(method, url, {}, form)
  }

  //
  // Private
  //
  send(method, url, params, data, appendExt = true) {
    const cancelable = axios.CancelToken.source()

    const promise = axios
      .request({
        url: appendExt ? `${url}.json` : url,
        method: method,
        params: params,
        data: data,
        cancelToken: cancelable.token,
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': this.token,
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
      .catch(error => {
        this.onFail(error)
        throw error
      })

    promise.cancel = (reason = 'Request canceled') => {
      cancelable.cancel(reason)
    }

    promise.then(response =>
      ReactGA.timing({
        category: 'API Performance',
        variable: response.config.url,
        label: response.config.method,
        value: response.duration,
      })
    )

    return promise
  }

  onFail(error) {
    if (axios.isCancel(error)) return

    const { response } = error
    const { data } = response

    if (response.status === 304) return

    if (response.status === 401) {
      location = '/users/sign_in'
      return
    }

    if (response.status === 503) {
      location = '/'
      return
    }

    let message = error.toString()

    if (typeof data === 'object') {
      if (response.status === 422) {
        if (data.error) {
          message = data.error
        } else if (data.errors) {
          message = Array.isArray(data.errors) ? data.errors.join('\n') : data.errors
        }
      } else {
        message = (
          <p>
            {data.type}: {data.error}
          </p>
        )
      }
    } else {
      message = 'Unknown Error'
      console.warn(data)
    }
    if (response.status !== 502) {
      Flash.error({ message: 'Server Error', description: message })
    }
  }
}

export default new Api()
